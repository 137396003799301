import { ApolloClient } from '@apollo/client';
import { from } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import apolloLogger from 'apollo-link-logger';
import createCache from './createCache';
import { setSessionExpired } from '../../actions/user';
import errorMessages from '../errors/messages/generic';
import { AuthenticationError } from '../errors';
import { parseError, formatApolloError } from '../errors/util';

export const link = store =>
  from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        // eslint-disable-next-line array-callback-return
        graphQLErrors.map((graphQLError, i) => {
          // replace graphQLErrors with custom Errors or generic
          const customError = parseError(graphQLError.message);
          // eslint-disable-next-line no-param-reassign
          graphQLErrors[i] =
            customError ||
            new Error(formatApolloError(errorMessages.defaultGraphQLError.id));
          const { message, locations, path } = graphQLError;
          console.warn(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
          if (customError instanceof AuthenticationError) {
            if (store) {
              store.dispatch(setSessionExpired());
            }
          }
        });
      }
      if (networkError) {
        // set default message error
        switch (networkError.statusCode) {
          case 503:
            window.location.href = '/';
            break;
          case 401:
            window.location.href = '/login';
            break;
          default:
            // replace the message with a default message id
            // eslint-disable-next-line no-param-reassign
            networkError.message = errorMessages.defaultNetworkError.id;
            console.warn(
              `[Network error]: ${
                errorMessages.defaultNetworkError.defaultMessage
              }`,
            );
            break;
        }
      }
    }),
    ...(__DEV__ ? [apolloLogger] : []),
    new HttpLink({
      uri: '/graphql',
      credentials: 'include',
    }),
  ]);

const cache = createCache();

export function createApolloClient(store) {
  return new ApolloClient({
    link: link(store),
    cache: cache.restore(window.App.apolloState),
    queryDeduplication: true,
    connectToDevTools: true,
  });
}
