import HealthCheck from '../components/HealthCheck';

const routes = [
  {
    path: '/',
    component: HealthCheck,
    exact: true,
  },
];

export default routes;
