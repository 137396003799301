import React from 'react';
import { useQuery, gql } from '@apollo/client';

const healthCheckQuery = gql`
  query healthCheckQuery {
    healthCheck {
      healthy
      timestamp
    }
    danubeHealthCheck
  }
`;

const HealthCheck = () => {
  const { loading, error, data } = useQuery(healthCheckQuery);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div>
      <p>healthy: {data.healthCheck.healthy ? 'yes' : 'no'}</p>
      <p>timestamp: {data.healthCheck.timestamp}</p>
      <p>danube api available: {data.danubeHealthCheck ? 'yes' : 'no'}</p>
    </div>
  );
};

export default HealthCheck;
