import React from 'react';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';

import s from './Expired.scss';
import messages from './messages';

class LicenseExpired extends React.Component {
  render() {
    return (
      <div className={s.expiredWrapper}>
        <h1>
          <FormattedMessage {...messages.heading} />
        </h1>
        <p>
          <FormattedMessage {...messages.info} />
        </p>
      </div>
    );
  }
}

export default withStyles(s)(LicenseExpired);
